export const ENV = {
  AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  AMPLITUDE_EXPERIMENT_DEPLOYMENT_ID: process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENT_DEPLOYMENT_ID,
  BRAND_NAME: process.env.NEXT_PUBLIC_BRAND_NAME,
  SERVER_URL: process.env.NEXT_PUBLIC_SERVER_URL,
  SCHIBSTED_SE_CLIENT_ID: process.env.NEXT_PUBLIC_SCHIBSTED_SE_CLIENT_ID,
  SCHIBSTED_FI_CLIENT_ID: process.env.NEXT_PUBLIC_SCHIBSTED_FI_CLIENT_ID,
  SCHIBSTED_CLIENT_ENV: process.env.NEXT_PUBLIC_SCHIBSTED_CLIENT_ENV,
  SCHIBSTED_SE_SESSION_DOMAIN: process.env.NEXT_PUBLIC_SCHIBSTED_SE_SESSION_DOMAIN,
  SCHIBSTED_FI_SESSION_DOMAIN: process.env.NEXT_PUBLIC_SCHIBSTED_FI_SESSION_DOMAIN,
  HOTJAR_SITE_ID: process.env.NEXT_PUBLIC_HOTJAR_SITE_ID,
  IMAGES_CDN_URL: process.env.NEXT_PUBLIC_IMAGES_CDN_URL,
  GOOGLE_ANALYTICS_TRACKING_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID,
  GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  GOOGLE_MAPS_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
  LOCATIONIQ_KEY: process.env.NEXT_PUBLIC_LOCATIONIQ_KEY,
  STRIPE_KEY: process.env.NEXT_PUBLIC_STRIPE_KEY,
  TINK_INCOME_CHECK_ID: process.env.NEXT_PUBLIC_TINK_INCOME_CHECK_ID,
  TINK_ACCOUNT_CHECK_ID: process.env.NEXT_PUBLIC_TINK_ACCOUNT_CHECK_ID,
  TINK_TEST_ENV: process.env.NEXT_PUBLIC_TINK_TEST_ENV,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SENTRY_DISABLED: process.env.NEXT_PUBLIC_SENTRY_DISABLED,
  INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  SUNSHINE_CONV_APP_ID: process.env.NEXT_PUBLIC_SUNSHINE_CONV_APP_ID,
  SUNSHINE_CONV_WHATSAPP_ID: process.env.NEXT_PUBLIC_SUNSHINE_CONV_WHATSAPP_ID,
  SCHIBSTED_COOKIE_CONSENT_BASE_ENDPOINT: process.env.NEXT_PUBLIC_SCHIBSTED_COOKIE_CONSENT_BASE_ENDPOINT,
  SCHIBSTED_COOKIE_CONSENT_PROPERTY_HREF: process.env.NEXT_PUBLIC_SCHIBSTED_COOKIE_CONSENT_PROPERTY_HREF,
  SCHIBSTED_COOKIE_CONSENT_PROPERTY_ID: process.env.NEXT_PUBLIC_SCHIBSTED_COOKIE_CONSENT_PROPERTY_ID,
  SCHIBSTED_COOKIE_CONSENT_GROUP_PM_ID: process.env.NEXT_PUBLIC_SCHIBSTED_COOKIE_CONSENT_GROUP_PM_ID,
  SCHIBSTED_COOKIE_CONSENT_DISABLED: process.env.NEXT_PUBLIC_SCHIBSTED_COOKIE_CONSENT_DISABLED,
  CONTENTFUL_KEY: process.env.NEXT_PUBLIC_CONTENTFUL_KEY,
  CONTENTFUL_SERVER_URL: process.env.NEXT_PUBLIC_CONTENTFUL_SERVER_URL,
  QASA_API_USER_TOKEN: process.env.QASA_API_USER_TOKEN,
  CRAWLING_ALLOWED: process.env.CRAWLING_ALLOWED,
  CONNECT_HOMEQ_ACCOUNT_TOGGLE: process.env.NEXT_PUBLIC_CONNECT_HOMEQ_ACCOUNT_TOGGLE,
}
